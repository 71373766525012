import siteLogo from '../../shared/img/logo.png';

export default function RestrictedPage() {
  return (
    <div className="App">
      <section id="authentication-layout">
        <div className="flex-container">
          <div className="img-area">
            <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
          </div>
          <div className="authentication-area" style={{ color: 'white' }}>
            <h6>You do not have access to this application.</h6>
            <h6>
              Please contact system administration ({' '}
              <a
                href="mailto:nicholas.mininni@teleflex.com"
                style={{ color: 'white' }}
              >
                nicholas.mininni@teleflex.com
              </a>{' '}
              ) to set up your account.
            </h6>
          </div>
        </div>
      </section>
    </div>
  );
}
